import gallery10 from '../assets/gallery10.png';
import gallery11 from '../assets/gallery11.png';
import gallery12 from '../assets/gallery12.png';
import gallery05 from '../assets/gallery05.png';

const imagess = {
    gallery10,
    gallery11,
    gallery12,
    gallery05,    
};
export default imagess;
