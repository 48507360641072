//Event.jsx

import React from 'react';
import './Event.css'; 

const Event = () => {
  return (
    <div className="event-container">
      <h4>Coming Soon!</h4>
      <p>We are preparing something awesome for you. Stay tuned!</p>
      {/* You can add a countdown timer or any other relevant information */}
    </div>
  );
};

export default Event;
