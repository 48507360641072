import images from './images';

const wines = [
  {
    title: ' Yo Yo This the Intro',
    price: '1',
    tags: 'Main Stream',
  },
  {
    title: ' The Blueprint to My Success',
    price: '2',
    tags: 'Main Stream',
  },
  {
    title: 'Flex for What',
    price: '3',
    tags: 'Main Stream',
  },
  {
    title: 'TrapHouse Jumpin TrapHouse Bumpin',
    price: '4',
    tags: 'Main Stream',
  },
  {
    title: ' B3an!',
    price: '5',
    tags: 'Main Stream',
  },
];

const cocktails = [
  {
    title: 'This Was Her First Time',
    price: '1',
    tags: 'Main Stream',
  },
  {
    title: "Sacrifices I Had to Make",
    price: '2',
    tags: 'Main Stream',
  },
  {
    title: 'The Difference Between Me and You',
    price: '3',
    tags: 'Main Stream',
  },
  {
    title: "Still Solid, Despite Your Fake Love",
    price: '4',
    tags: 'Main Stream',
  },
  {
    title: ' Spend a Check',
    price: '5',
    tags: 'Main Stream',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Collaboration 1',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Recognition 2',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'Collaboration',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding ',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

const data = { wines, cocktails, awards };

export default data;

